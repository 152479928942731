<template>
  <div class="app-container">
    <div style="width: 100%">
      <img src="../../assets/images/IOT/u1142_div.png" width="100%">
    </div>
    <div class="mt20">
      <view1/>
    </div>
    <div class="mt20">
      <view2/>
    </div>
    <div class="mt20">
      <view3/>
    </div>
    <div class="mt20">
      <bottom/>
    </div>
  </div>
</template>

<script>
import view1 from "@/views/IOT/component/view1";
import view2 from "@/views/IOT/component/view2";
import view3 from "@/views/IOT/component/view3";
import bottom from "@/components/bottom";
export default {
  name: "index",
  components: {
    view1,
    view2,
    view3,
    bottom
  }
}
</script>

<style scoped>

</style>
