<template>
  <div class="IOT-view2-main" @mouseover="show = true" @mouseleave="show = false">
    <div style="width: 100%">
      <img src="../../../assets/images/IOT/u1235_div.png" width="100%">
    </div>
    <div class="IOT-view2-main-title" >
      <div :class="{'IOT-view2-main-title-active': show, 'IOT-view2-main-title-active1': !show}">
        {{ $t('lang.WhatIsTheRoadToKCXD') }}
      </div>
      <div style="width: 70%; height: 5px;background: #00834c" class="mt10" :class="{'IOT-view2-main-title-active': show, 'IOT-view2-main-title-active1': !show}">
      </div>
    </div>
    <div class="IOT-view2-main-content">
      <div class="IOT-view2-main-content-div">
        <div class="IOT-view2-main-content-div-text"  :class="{'IOT-view2-main-content-div-text-active': !show, 'IOT-view2-main-content-div-text-active1': show}">
          <img src="../../../assets/images/IOT/time.png"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "view2",
  data() {
    return {
      show: false,
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes down {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(200px);
  }
}
@keyframes up {
  0% {
    transform: translateY(200px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes right1 {
  0% {
    right: 0px;
  }
  100% {
    right: -1411px;
  }
}
@keyframes right2 {
  0% {
    right: 1411px;
  }
  100% {
    right: 0px;
  }
}
.IOT-view2-main {
  position: relative;
  .IOT-view2-main-title {
    position: absolute;
    overflow: hidden;
    top: calc(50% - 60px);
    left: 50%;
    transform:translate(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
    font-weight: 850;
    font-style: normal;
    font-size: 50px;
  }
  .IOT-view2-main-title-active {
    animation: down 1s;
    animation-fill-mode:forwards;
  }
  .IOT-view2-main-title-active1 {
    animation: up 1s;
    animation-fill-mode:forwards;
  }
  .IOT-view2-main-content {
    position: absolute;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .IOT-view2-main-content-div {
      position: relative;
      width: 1411px;
      height: 458px;
      overflow: hidden;
      .IOT-view2-main-content-div-text {
        position: absolute;
        top: 0px;
        right: 0px;

      }
      .IOT-view2-main-content-div-text-active {
        animation: right1 1s;
        animation-fill-mode:forwards;
      }
      .IOT-view2-main-content-div-text-active1 {
        animation: right2 1s;
        animation-fill-mode:forwards;
      }
    }
  }
}
</style>
