<template>
  <div class="IOT-view3-main">
    <div class="IOT-view3-content">
      <div class="IOT-view3-content-text1">
        {{ $t('lang.advantageInfo.OurAdvantage') }}
      </div>
      <div style=" width: 200px;border-bottom: 5px solid #00834c;"></div>
      <div class="IOT-view3-content-text2 ">
        <div class="IOT-view3-content-text2-item">
          <img src="../../../assets/images/IOT/u1269.png" alt="">
          <div class="mt10"> {{ $t('lang.advantageInfo.msg1') }}</div>
        </div>
        <div class="IOT-view3-content-text2-item">
          <img src="../../../assets/images/IOT/u1272.png" alt="">
          <div class="mt10"> {{ $t('lang.advantageInfo.msg2') }}</div>
        </div>
        <div class="IOT-view3-content-text2-item">
          <img src="../../../assets/images/IOT/u1275.png" alt="">
          <div class="mt10"> {{ $t('lang.advantageInfo.msg3') }}</div>
        </div>
        <div class="IOT-view3-content-text2-item">
          <img src="../../../assets/images/IOT/u1278.png" alt="">
          <div class="mt10"> {{ $t('lang.advantageInfo.msg4') }}</div>
        </div>
        <div class="IOT-view3-content-text2-item">
          <img src="../../../assets/images/IOT/u1281.png" alt="">
          <div class="mt10"> {{ $t('lang.advantageInfo.msg5') }}</div>
        </div>
        <div class="IOT-view3-content-text2-item">
          <img src="../../../assets/images/IOT/u1284.png" alt="">
          <div class="mt10"> {{ $t('lang.advantageInfo.msg6') }}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "view3"
}
</script>

<style lang="scss" scoped>
.IOT-view3-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .IOT-view3-content {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .IOT-view3-content-text1 {
      font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
      font-weight: 650;
      font-style: normal;
      font-size: 50px;
      text-align: center;
      padding-bottom: 20px;
      color: #00834c;
    }

    .IOT-view3-content-text2 {
      margin-top: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .IOT-view3-content-text2-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 15%;
        font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
        font-weight: 650;
        font-style: normal;
        font-size: 30px;
        text-align: center;
        padding-bottom: 20px;
      }
    }
  }
}
</style>
