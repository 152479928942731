<template>
  <div class="IOT-view1-main">
    <div class="IOT-view1-content">
      <div class="title-1">
        {{ $t('lang.IoTInfo.title') }}
      </div>
      <div class="flex mt20" style="width: 100%">
        <div style="width: calc(50% - 100px)" class="flex flex-column align-items-center justify-content-center">
          <div class="flex flex-column align-items-center justify-content-center" style="max-width: 600px">
            <div class="IOT-view1-title3">
              <p>{{ $t('lang.IoTInfo.msg1') }}</p>
              <p>{{ $t('lang.IoTInfo.msg2') }}</p>

            </div>
            <div class="flex align-items-center justify-content-start IOT-view1-title4">
              <div class="IOT-view1-title4-div1">超大型集团-MES系统</div>
              <div class="IOT-view1-title4-div2">打通养殖场与集团管理系统的信息断层</div>
            </div>
            <div class="flex align-items-center justify-content-start IOT-view1-title4">
              <div class="IOT-view1-title4-div1">超大型集团-MES系统</div>
              <div class="IOT-view1-title4-div2">基于现有PaaS系统的定制化开发</div>
            </div>
            <div class="flex align-items-center justify-content-start IOT-view1-title4">
              <div class="IOT-view1-title4-div1">超大型集团-MES系统</div>
              <div class="IOT-view1-title4-div2">标准化的物联网软件生产管理软件</div>
            </div>
          </div>

        </div>
        <div style="width: 200px" class="flex align-items-center justify-content-center">
          <img src="../../../assets/images/IOT/o2s.png" style="width: 100%"/>
        </div>
        <div style="width: calc(50% - 100px)" class="flex align-items-center justify-content-center">
          <div style="max-width: 600px; height: 100%" class="flex flex-warp align-items-center justify-content-between">
            <div style="width: 48%;height: 50%">
              <img src="../../../assets/images/IOT/iotImg1.png" width="100%"/>
              <div class="flex align-items-center justify-content-center">
                <div class="IOT-view1-title5">集团大屏</div>
              </div>
            </div>
            <div style="width: 48%;height: 50%">
              <img src="../../../assets/images/IOT/iotImg2.png" width="100%"/>
              <div class="flex align-items-center justify-content-center">
                <div class="IOT-view1-title5">电 脑 端</div>
              </div>
            </div>
            <div style="width: 48%;height: 50%">
              <img src="../../../assets/images/IOT/iotImg3.png" width="100%"/>
              <div class="flex align-items-center justify-content-center">
                <div class="IOT-view1-title5">P a d端</div>
              </div>
            </div>
            <div style="width: 48%;height: 50%">
              <img src="../../../assets/images/IOT/iotImg4.png" width="100%"/>
              <div class="flex align-items-center justify-content-center">
                <div class="IOT-view1-title5">A P P端</div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="mt20">-->
<!--        <img :src="`${host}/resource/images/kcow/1.jpg`" alt="" width="100%">-->
<!--      </div>-->
<!--      <div class="IOT-view1-content-text2">-->
<!--        <ul>-->
<!--          <li style="">-->
<!--            <span>{{ $t('lang.IoTInfo.msg1') }}</span>-->
<!--          </li>-->
<!--          <li style="">-->
<!--            <span>{{ $t('lang.IoTInfo.msg2') }}</span>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
    </div>

  </div>
</template>

<script>
export default {
  name: "view1",
  data() {
    return {
      host: process.env.VUE_APP_BASE_API.split(':')[0] + ':' + process.env.VUE_APP_BASE_API.split(':')[1]
    }
  },
}
</script>

<style lang="scss" scoped>
.IOT-view1-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .IOT-view1-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .IOT-view1-content-text1 {
      font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
      font-weight: 650;
      font-style: normal;
      font-size: 50px;
      text-align: center;
      padding-bottom: 20px;
    }

    .IOT-view1-content-text2 {
      font-family: 'PingFangTC-Regular', 'PingFang TC', sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 22px;
      line-height: 200%;
      color: #000000;
    }
  }
}
.IOT-view1-title3 {
  font-family: 'ArialMT', 'Arial', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  letter-spacing: normal;
  color: #000000;
  text-transform: none;
  text-indent:2em;
  line-height: 35px;
}
.IOT-view1-title4 {
  width: 100%;
  padding: 20px 0;
  .IOT-view1-title4-div1 {
    padding: 5px 20px;
    background: #00834b;
    color: white;
    font-size: 18px;
    font-weight: 600;
  }
  .IOT-view1-title4-div2 {
    padding: 5px 20px;
    color: #00834b;
    font-size: 18px;
    font-weight: 600;
  }
}
.IOT-view1-title5 {
  padding: 5px 20px;
  background: #00834b;
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
}
</style>
